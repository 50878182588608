import { mapActions, mapState } from 'vuex'
import { getTransactions } from '@/queries/transactions/getTransactions'
import { getCompetence } from '@/queries/competences/competences.queries'
import { formatDate, currencyFormat } from '@/helpers'

export default {
  name: 'TCompetences',
  title: 'Competências da doação',
  components: {
    TCompetencesTable: () => import('@/components/competences/table.vue'),
  },
  data () {
    return {
      localCompetenceId: null,
      totalPages: "0",
      currentPage: "0",
      pagesSelected: "0",
      total: 0,
      limit: 10,
      originalDueDate: null,
      competenceDetail: {}
    }
  },
  computed: {
    ...mapState({
      competences: ({ competences }) => competences.competences,
      metaDataList: ({ competences }) => competences.metaDataList,
      orderParams: ({ competences }) => competences.orderParams,
      isLoading: ({ competences }) => competences.isLoading,
    }),
    formattedTitle: ({ competenceOriginalDueDate }) => `<span class="title-bank">Transações da Competência</span> <small class="title-date"> - ${ competenceOriginalDueDate }</small>`,
    competenceOriginalDueDate: ({ competenceDetail }) => {
      if (competenceDetail.originalDueDate) {
        const { originalDueDate } = competenceDetail
        const formatedDate = formatDate(originalDueDate)
        return formatedDate.substring(3);
      }
      return ''
    },
    monthlyAmount: ({ competenceDetail }) => currencyFormat(competenceDetail.amount)
  },
  methods: {
    ...mapActions('competences', [ 'setCompetences', 'setMetaDataList', 'setOrderParams', 'toggleLoadingList' ]),

    searchTransactions (params = {}) {

      let searchParams = this.mountSearchParams()
      if (!params.competenceId) {
        params.competenceId = this.localCompetenceId
      }

      searchParams = {
        ...searchParams,
        ...params
      }

      this.toggleLoadingList(true)
      getTransactions( searchParams ).then(res => {
        const { collection, metadata } = res.data.paymentIntents
        this.setCompetences(collection)
        this.competenceDetail.donorName = `${this.competences[0].customer.firstName} ${this.competences[0].customer.lastName}`
        this.competenceDetail.donorId = this.competences[0].customer.id
        this.setMetaDataList(metadata)
        this.updateMetaDataTable()
        this.toggleLoadingList()
      }).catch(e => {
        Vue.$log.error('error', e)
        this.toggleLoadingList()
      })
    },

    orderBy (orderObject) {
      const { field, order } = orderObject
      this.setOrderParams({ orderBy: field, order })
      this.searchTransactions()
    },

    prevPage () {
      if (this.currentPage === 1) return
      const page = Number(this.currentPage) - 1
      let params = { page }
      this.searchTransactions( params )
    },

    nextPage () {
      if (this.currentPage === this.totalPages) return
      const page = Number(this.currentPage) + 1
      let params = { page }
      this.searchTransactions( params )
    },

    goToPage (pageNumber) {
      const page = Number(pageNumber)
      if (Number(page) < 0 || Number(page) > Number(this.totalPages)) return
      let params = { page }
      this.searchTransactions( params )
    },

    updateMetaDataTable () {
      if (this.metaDataList) {
        this.totalPages = `${this.metaDataList.totalPages}`
        this.currentPage = `${this.metaDataList.currentPage}`
        this.pagesSelected = `${this.metaDataList.limitValue}`
        this.total = this.metaDataList.totalCount
      }
    },

    mountSearchParams () {
      let params = {}

      if (this.limit) {
        params.limit = this.limit
      }

      if (this.orderParams) {
        params.orderBy = this.orderParams.orderBy
        params.order = this.orderParams.order
      }

      return params
    },

    searchCompetence(id) {
      getCompetence(id).then(res => {
        this.competenceDetail = res.data.competence
      }).catch(e => {
        Vue.$log.error('error', e)
      })
    }

  },
  mounted () {
    this.localCompetenceId = this.$route.params.competenceId
    this.searchCompetence(this.localCompetenceId)
    this.searchTransactions({ competenceId: this.localCompetenceId })
  }
}
